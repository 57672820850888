<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 v-if="storage.itemId">
        <v-layout wrap>
          <v-flex xs2 sm2 v-if="storage.itemId.productId">
            <v-img
              :src="mediaUURL + storage.itemId.productId.photos[0]"
              height="100%"
              width="auto"
              contain
              :alt="storage.itemId.productItemName"
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
          </v-flex>
          <v-flex xs9 sm5>
            <v-layout wrap justify-center fill-height>
              <v-flex xs12 sm12 md12 align-self-center pl-2 text-left>
                <v-layout
                  wrap
                  justify-start
                  style="line-height: 16px"
                  fill-height
                  v-if="storage.itemId"
                >
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 15px;
                        color: #191919;
                      "
                    >
                      {{ storage.itemId.productItemName }}
                      <!-- <span v-if="list.name.length > 25"> ... </span> -->
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-3 v-if="storage.itemId">
                    <span
                      style="
                        color: #191919;
                        font-size: 13px;
                        font-family: poppinsmedium;
                      "
                      >Size
                      <span style="font-family: poppinsregular"
                        >: {{ storage.itemId.size }}</span
                      >
                    </span>
                  </v-flex>
                  <!-- <v-flex xs12 pt-1>
                    <span
                      style="
                        color: #191919;
                        font-size: 13px;
                        font-family: poppinsmedium;
                      "
                      >OTY
                      <span style="font-family: poppinsregular"
                        >: {{ storage.quantity }}
                      </span>
                    </span>
                  </v-flex> -->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs11 sm4>
            <v-layout wrap fill-height v-if="storage.userId">
              <v-flex xs12 sm12 md12 align-self-center text-left>
                <v-layout wrap>
                  <v-flex xs12>
                    <span class="orderHeading">Customer Details</span>
                  </v-flex>
                  <v-flex xs12 pt-2>
                    <span
                      style="
                        color: #7e8f85;
                        font-family: poppinsregular;
                        font-size: 14px;
                      "
                      >Name :
                    </span>
                    <span class="orderText">{{ storage.userId.name }}</span>
                  </v-flex>
                  <v-flex xs12>
                    <span
                      style="
                        color: #7e8f85;
                        font-family: poppinsregular;
                        font-size: 14px;
                      "
                      >Phone :
                    </span>
                    <span class="orderText">{{ storage.userId.phone }}</span>
                  </v-flex>
                  <v-flex xs12>
                    <span
                      style="
                        color: #7e8f85;
                        font-family: poppinsregular;
                        font-size: 14px;
                      "
                      >Email :
                    </span>
                    <span class="orderText">{{ storage.userId.email }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs1 sm1>
            <v-switch
              v-model="storage.showFeedback"
              :true-value=true
              :false-value=false
              color="#5DC57C"
              hide-details
              :ripple="false"
              class="statusKey"
              :loading="statusLoading"
              @click="changeStatus(storage)"
            >
              <!-- <template slot="placeholder">
                        <span
                          style="
                            color: #000000;
                            font-family: opensanssemibold;
                            font-size: 16px;
                          "
                        >
                          {{ item.status }}
                        </span>
                      </template> -->
            </v-switch>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      statusLoading: false,
    };
  },
  methods:{
    changeStatus(item) {
      this.statusLoading = true;
      axios({
        method: "POST",
        url: "/feedback/statusChange",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Review Status Changed to " + item.status;
            this.showSnackBar = true;
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  }
};
</script>